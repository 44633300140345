import FirebaseFirestore from "@firebase/firestore-types";

export enum EEventKind {
  Recorder = "recorder",
  Uploader = "uploader",
}

export type TEvent = {
  id?: string;
  kind?: EEventKind;
  title: string;
  code?: string;
  date: string;
  releaseDate: string;
  bannerUrl?: string;
  languages?: string[];
  city?: string;
  state?: string;
  country?: string;
  totalGuests?: string;
  contacts?: TContact[];
  searchIndex?: string[];
  customization?: TCustomization;
  compressed_video_collection_url?: string;
  createdAt?: string;
  updatedAt?: string;
  owners?: string[];
};

export type TVideo = {
  id?: string;
  eventId: string;
  eventCode: string;
  status: string;
  thumbnailFileName: string;
  thumbnailUrl: string;
  videoFileExtension: string;
  videoFileName: string;
  videoUrl: string;
  createdAt?: string;
  updatedAt?: string;
};

export type TUpload = {
  id?: string;
  eventId: string;
  eventCode: string;
  status: string;
  fileName: string;
  fileUrl: string;
  fileExt: string;
  fileSize: number;
  fileStoragePath: string;
  thumbnailStoragePath?: string;
  optimizedFileStoragePath?: string;
  createdAt?: string;
  updatedAt?: string;
};

type TCustomization = {
  theme: TThemeProps;
  template: TTemplateProps;
};

type TThemeProps = {
  name: string;
  themeColor: string;
  backgroundColor: string;
  cardShape: string;
  buttonShape: string;
};

export type TTemplateProps = {
  name: string;
  titleFontSize?: string;
  titleFontFamily?: string;
  titleOneText?: string;
  titleTwoText?: string;
  subtitleText?: string;
};

export type TContact = {
  name: string;
  email?: string;
  whatsapp?: string;
  instagram?: string;
  reference?: string;
  notify?: string;
  role?: string;
};

export type TLog = {
  id?: string;
  eventId: string;
  message: string;
  createdAt?: string;
  updatedAt?: string;
};

export type TDocument = FirebaseFirestore.DocumentData;

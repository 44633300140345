type InputProps = {
  label: string;
  register: any;
  error: any;
  field: string;
  type?: string;
  defaultValue?: string;
  options?: { label: string; value: string }[];
} & React.InputHTMLAttributes<HTMLInputElement>;

function InputRadio({
  label,
  register,
  error,
  field,
  type,
  options,
  ...rest
}: InputProps) {
  return (
    <div className="w-full flex flex-col items-start">
      <label className="flex items-center gap-1.5">
        {label}
        {error && <span className="text-red-500">[{error.message}]</span>}
      </label>
      <div className="flex items-center gap-5">
        {options?.map((option) => {
          return (
            <label
              key={option.value}
              htmlFor={option.value}
              className="flex items-center justify-start gap-1"
            >
              <input
                type="radio"
                id={option.value}
                {...register}
                {...rest}
                value={option.value}
                defaultChecked={option.value === rest.value}
                className="focus:outline-none focus:ring-0 w-5"
              />
              {option.label}
            </label>
          );
        })}
      </div>
    </div>
  );
}

export default InputRadio;

import { createBrowserRouter, RouterProvider } from "react-router-dom";
import ShowEvent from "../pages/admin/events/ShowEvent";
import Events from "../pages/admin/events/Events";
import AdminLayout from "../layouts/admin/AppLayout";
import EventLayout from "layouts/admin/EventLayout";
import PublicLayout from "../layouts/public/AppLayout";
import EventActions from "../pages/admin/events/EventActions";
import PublicHome from "../pages/public/Home";
import PublicLogin from "../pages/public/Login";
import PublicForgotPassword from "../pages/public/ForgotPassword";
import PublicNewUser from "../pages/public/NewUser";
import { getEvents, getEventById } from "../services/events";
import VideosEvent from "../pages/admin/events/VideosEvent";
import {
  getDataForVideoDownload,
  getVideosByEventId,
} from "../services/videos";
import Login from "../pages/admin/Login";
import VideosDownload from "../pages/download/VideosDownload";
import LogsEvent from "../pages/admin/events/LogsEvent";
import { getLogsByEventId } from "../services/logs";
import Home from "../pages/admin/Home";
import { getHomeData } from "../services/home";
import UploadsEvent from "../pages/admin/events/UploadsEvent";
import { uploadsLoader, uploadDownloadLoader } from "../services/uploads";
import PublicEventNotFound from "../pages/public/EventNotFound";
import { adminAuthLoader, publicAuthLoader } from "../services/auth";
import UploadsDownload from "../pages/download/UploadsDownload";
import EventCreationLayout from "layouts/admin/EventCreationLayout";
import CreateEvent from "../pages/admin/events/CreateEvent";
import CreateEventForCustomerEdition from "../pages/admin/events/CreateEventForCustomerEdition";
import * as customerService from "../services/customer";

export default function AppRouter() {
  const router = createBrowserRouter([
    {
      element: <AdminLayout />,
      loader: adminAuthLoader,
      path: "/admin",
      children: [
        {
          path: "",
          element: <Home />,
          loader: getHomeData,
        },
        {
          path: "events",
          element: <Events />,
          loader: getEvents,
        },
        {
          path: "events/create",
          element: <EventCreationLayout />,
          children: [
            {
              path: "",
              element: <CreateEvent />,
            },
            {
              path: "for-customer-edition",
              element: <CreateEventForCustomerEdition />,
            },
          ],
        },
        {
          path: "events/:id",
          element: <EventLayout />,
          loader: getEventById,
          children: [
            {
              path: "",
              element: <ShowEvent />,
            },
            {
              path: "actions",
              element: <EventActions />,
            },
            {
              path: "videos",
              element: <VideosEvent />,
              loader: getVideosByEventId,
            },
            {
              path: "uploads",
              element: <UploadsEvent />,
              loader: uploadsLoader,
            },
            {
              path: "logs",
              element: <LogsEvent />,
              loader: getLogsByEventId,
            },
          ],
        },
      ],
    },
    {
      path: "/admin/login",
      element: <Login />,
    },
    {
      path: "/public/:eventCode",
      element: <PublicLayout />,
      loader: publicAuthLoader,
      children: [
        {
          path: "",
          element: <PublicHome />,
          loader: customerService.eventLoader,
        },
      ],
    },
    {
      path: "/public/:eventCode/login",
      element: <PublicLogin />,
    },
    {
      path: "/public/:eventCode/new-account",
      element: <PublicNewUser />,
    },
    {
      path: "/public/:eventCode/forgot-password",
      element: <PublicForgotPassword />,
    },
    {
      path: "/public/event-not-found",
      element: <PublicEventNotFound />,
    },
    {
      path: "/download/:id",
      element: <VideosDownload />,
      loader: getDataForVideoDownload,
    },
    {
      path: "/download/upload/:id",
      element: <UploadsDownload />,
      loader: uploadDownloadLoader,
    },
  ]);

  return <RouterProvider router={router} />;
}

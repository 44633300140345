import { TEvent, TUpload } from "@services/types";
import { Gallery, Image } from "react-grid-gallery";
import { useLoaderData } from "react-router-dom";
import * as DigitalOceanSpaces from "../../services/digital_ocean/spaces";
import { useEffect, useMemo, useState } from "react";
import {
  Lightbox,
  IconButton,
  createIcon,
  SlideImage,
  SlideVideo,
  useLightboxState,
  addToolbarButton,
} from "yet-another-react-lightbox";
import {
  Counter,
  Fullscreen,
  Slideshow,
  Video,
} from "yet-another-react-lightbox/plugins";
import "yet-another-react-lightbox/styles.css";
import "yet-another-react-lightbox/plugins/counter.css";
import { Play, Download, SpinnerIcon } from "../../assets/svg";

type TEventExtended = TEvent & { uploads: TUpload[] };

const VIDEO_FILE_EXTENSIONS = ["mp4", "webm", "mov"];

const isVideo = (fileExt: string) =>
  VIDEO_FILE_EXTENSIONS.includes(fileExt.toLowerCase());

const DownloadIcon = createIcon(
  "Download",
  <Download width={22} height={22} />
);

const DownloadToast = () => {
  return (
    <div className="w-[300px] bg-blue-500 absolute right-0 bottom-0 mr-5 mb-5 flex items-center z-10000">
      <div className="h-full flex items-center justify-center p-5">
        <SpinnerIcon width={36} height={36} color="white" outline="black" />
      </div>

      <span className="text-white font-century-gothic-bold">
        Preparando o download...
      </span>
    </div>
  );
};

const ThumbnailImageComponent = (props: any) => {
  const { item, imageProps } = props;

  const lastDotIndex = item.alt.lastIndexOf(".");
  const fileExt = item.alt.slice(lastDotIndex + 1);

  const { key, ...otherImageProps } = imageProps;

  if (isVideo(fileExt)) {
    return (
      <div key={key} className="w-full h-full relative">
        <img alt="" {...otherImageProps} />
        <div className="absolute inset-0 flex items-center justify-center cursor-pointer">
          <Play width={32} height={32} color="#fff" />
        </div>
      </div>
    );
  }

  return <img key={key} alt="" {...otherImageProps} />;
};

export default function Home() {
  const event = useLoaderData() as TEventExtended;
  const title = event?.customization?.template?.titleOneText;

  const [images, setImages] = useState<Image[]>([]);
  const [slides, setSlides] = useState<(SlideImage | SlideVideo)[]>([]);
  const [fileIndex, setFileIndex] = useState<number>(-1);
  const [showDownloadToast, setShowDownloadToast] = useState(false);
  const [galleryRowHeight, setGalleryRowHeight] = useState(200);

  const handleGalleryClick = (index: number) => setFileIndex(index);
  const handleSlideshowClose = () => setFileIndex(-1);

  function DownloadButton() {
    const { currentIndex } = useLightboxState();

    const downloadFileOnClick = async (filename: string, url: string) => {
      setShowDownloadToast(true);

      try {
        const response = await fetch(url);
        const blob = await response.blob();
        const fileUrl = window.URL.createObjectURL(blob);

        const linkElement = document.createElement("a");

        linkElement.style.display = "none";
        linkElement.href = fileUrl;
        linkElement.download = filename;

        document.body.appendChild(linkElement);

        linkElement.click();

        document.body.removeChild(linkElement);

        window.URL.revokeObjectURL(fileUrl);
      } catch (error) {
        console.error("Error downloading", JSON.stringify(error));
      } finally {
        setShowDownloadToast(false);
      }
    };

    const handleDownload = async () => {
      const upload = event.uploads[currentIndex];
      const { fileName, fileStoragePath } = upload;

      const url = await DigitalOceanSpaces.getFileUrl(fileStoragePath);

      await downloadFileOnClick(fileName, url);
    };

    return (
      <IconButton
        label="Download"
        icon={DownloadIcon}
        onClick={handleDownload}
        disabled={showDownloadToast}
      />
    );
  }

  function CustomDownloadPlugin({ augment }: { augment: any }) {
    augment(({ toolbar, ...restProps }: { toolbar: any }) => ({
      toolbar: addToolbarButton(toolbar, "download", <DownloadButton />),
      ...restProps,
    }));
  }

  const openSlideshow = useMemo(() => {
    return fileIndex >= 0;
  }, [fileIndex]);

  useEffect(() => {
    const setImagesAsync = async () => {
      const images = event.uploads.map(async (upload) => {
        const thumbnailKey = upload.thumbnailStoragePath;
        const image: Image = {
          src: await DigitalOceanSpaces.getFileUrl(thumbnailKey as string),
          width: 200,
          height: 200,
          alt: upload.fileName,
        };

        return image;
      });

      setImages(await Promise.all(images));
    };

    const setSlidesAsync = async () => {
      const slides = event.uploads.map(async (upload) => {
        const { fileName, fileExt, fileStoragePath, optimizedFileStoragePath } =
          upload;
        const srcPath = optimizedFileStoragePath || fileStoragePath;
        const src = await DigitalOceanSpaces.getFileUrl(srcPath);

        if (isVideo(fileExt)) {
          return {
            type: "video",
            controls: true,
            autoPlay: true,
            sources: [
              {
                src,
                type: `video/mp4`,
              },
            ],
            title: fileName,
          } as SlideVideo;
        }

        return {
          type: "image",
          src,
          alt: fileName,
          title: fileName,
        } as SlideImage;
      });

      setSlides(await Promise.all(slides));
    };

    setImagesAsync();
    setSlidesAsync();
  }, [event]);

  useEffect(() => {
    // check if desktop or mobile
    const isDesktop = window.innerWidth > 768;

    if (isDesktop) {
      setGalleryRowHeight(300);
    }
  }, []);

  return (
    <div>
      <h1>{title}</h1>

      <div className="mt-8 bg-white">
        <Gallery
          thumbnailImageComponent={ThumbnailImageComponent}
          images={images}
          onClick={handleGalleryClick}
          enableImageSelection={false}
          rowHeight={galleryRowHeight}
        />

        <Lightbox
          slides={slides}
          open={openSlideshow}
          index={fileIndex}
          close={handleSlideshowClose}
          plugins={[
            Counter,
            CustomDownloadPlugin,
            Fullscreen,
            Slideshow,
            Video,
          ]}
        />
      </div>

      {showDownloadToast && <DownloadToast />}
    </div>
  );
}

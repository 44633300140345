import { useFieldArray, useForm } from "react-hook-form";
import { createEvent } from "../../../services/events";
import { useNavigate } from "react-router-dom";
import { InputSelect, InputText } from "components/ui/form";
import { EEventKind } from "../../../services/types";
import Button from "components/ui/Button";
import { TEventFormValues } from "components/events/EventForm";
import dayjs from "dayjs";

export default function CreateEventForCustomerEdition() {
  const navigate = useNavigate();
  const {
    register,
    control,
    watch,
    handleSubmit,
    formState: { errors },
  } = useForm<TEventFormValues>({
    defaultValues: {
      title: "",
      date: "",
      releaseDate: "",
      kind: EEventKind.Recorder,
      owners: [{ email: "" }],
    },
  });

  const {
    fields: ownersFields,
    append: ownersAppend,
    remove: removeAppend,
  } = useFieldArray({ control, name: "owners", rules: { required: true } });

  const dateString = watch("date");

  const handleCreate = async (data: TEventFormValues) => {
    // create contacts array
    const contacts = data.owners.map(({ email }) => ({
      name: "",
      email,
      whatsapp: "",
      reference: "",
      instagram: "",
      notify: "true",
    }));

    const newEvent = {
      ...data,
      contacts,
    };

    const eventId = await createEvent(newEvent);

    if (!eventId) {
      throw Error("event can't be saved");
    }

    navigate(`/admin/events/${eventId}`);
  };

  const addOwner = () => {
    ownersAppend({ email: "" });
  };

  const removeOwner = (index: number) => removeAppend(index);

  const validateDate = (value: string) => {
    if (!value) {
      return true;
    }

    const date = dayjs(value);
    const today = dayjs().startOf("day");

    if (dayjs(date).isBefore(today)) {
      return "A data do evento deve igual ou maior que hoje";
    }

    return true;
  };

  const validateReleaseDate = (value: string) => {
    if (!value || !dateString) {
      return true;
    }

    const minLimit = dayjs(dateString).add(2, "day");
    const releaseDate = dayjs(value);

    if (releaseDate.isBefore(minLimit)) {
      return "A data de entrega deve ser ter uma diferença de 2 dias da data do evento";
    }

    return true;
  };

  const validateEmail = (value: string) => {
    if (!value) {
      return true;
    }

    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(value)) {
      return "Email inválido";
    }

    return true;
  };

  return (
    <form
      autoComplete="off"
      className="w-full"
      onSubmit={handleSubmit(handleCreate)}
      noValidate={true}
      onKeyDown={(event) => {
        if (event.key === "Enter") {
          event.preventDefault();
        }
      }}
    >
      <h2 className="mb-5">Dados do evento</h2>
      <div className="w-full flex items-start gap-5">
        <div className="w-1/2 flex flex-col items-start gap-3">
          <input type="hidden" {...register("code")} id="code" />
          <InputText
            label="Título Interno*"
            register={register("title", { required: "Obrigatório" })}
            field="title"
            type="text"
            error={errors.title}
            isEditable={true}
          />

          <InputSelect
            label="Tipo*"
            register={register("kind", { required: "Obrigatório" })}
            field="kind"
            options={[
              { label: "Recorder", value: EEventKind.Recorder },
              { label: "Uploader", value: EEventKind.Uploader },
            ]}
            error={errors.kind}
            isEditable={true}
          />
        </div>

        <div className="w-1/2 flex flex-col items-start gap-3">
          <input type="hidden" {...register("code")} id="code" />

          <InputText
            label="Data do Evento* (último dia do evento)"
            register={register("date", {
              required: "Obrigatório",
              validate: validateDate,
            })}
            field="date"
            type="date"
            error={errors.date}
            isEditable={true}
          />

          <InputText
            label="Data de Entrega*"
            register={register("releaseDate", {
              required: "Obrigatório",
              validate: validateReleaseDate,
            })}
            field="releaseDate"
            type="date"
            error={errors.releaseDate}
            isEditable={true}
          />
        </div>
      </div>

      <h2 className="mt-10 mb-5">Quem pode alterar este evento?</h2>

      <div className="w-full space-y-5">
        {errors.owners?.root && (
          <p className="text-red-500">
            É obrigatório informar ao menos um proprietário.
          </p>
        )}

        {ownersFields?.map((owner, index) => (
          <div className="flex gap-5" key={owner.id}>
            <div className="w-1/2 flex">
              <InputText
                label="Email"
                register={register(`owners.${index}.email`, {
                  required: "Obrigatório",
                  validate: validateEmail,
                })}
                field={`owners.${index}`}
                type="email"
                error={errors.owners?.[index]?.email}
                isEditable={true}
              />
            </div>

            <div className="flex items-end">
              <Button
                className="bg-rose-200"
                onClick={() => removeOwner(index)}
              >
                Remover
              </Button>
            </div>
          </div>
        ))}

        <Button className="max-w-[200px] bg-rose-200" onClick={addOwner}>
          Adicionar proprietário
        </Button>
      </div>

      <div className="py-20 w-full flex items-center justify-center">
        <Button type="submit" className="max-w-[150px] bg-green-200">
          Salvar
        </Button>
      </div>
    </form>
  );
}

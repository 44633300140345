import { TEvent } from "@services/types";

export const formatDate = (date: string) => {
  if (date) {
    const newDate = new Date(date);
    return newDate.toLocaleDateString("pt-br", {
      timeZone: "UTC",
    });
  }
};

export const formatDateTime = (date: string) => {
  if (date) {
    const newDate = new Date(date);
    return newDate.toLocaleString("pt-br", {
      timeZone: "UTC",
    });
  }
};

export function formatDateTimeShort(dateStr: string) {
  const date = new Date(dateStr);

  // Extract components from the date
  let day = date.getDate().toString().padStart(2, "0");
  let month = (date.getMonth() + 1).toString().padStart(2, "0"); // Months are 0-based
  let hours = date.getHours().toString().padStart(2, "0");
  let minutes = date.getMinutes().toString().padStart(2, "0");

  // Construct the formatted date string
  return `${day}/${month} ${hours}:${minutes}`;
}

export const convertToDate = (date: any) => {
  if (date) {
    if (date instanceof Date) return date;

    const newDate = new Date();
    const [year, month, day] = date.split("-");
    newDate.setHours(0, 0, 0, 0);
    newDate.setDate(day);
    newDate.setMonth(month - 1);
    newDate.setFullYear(year);

    return newDate;
  }
  return null;
};

export const dateToString = (date: Date) => {
  //if date is not a Date, return date
  if (!(date instanceof Date)) return date;

  if (date) {
    const newDate = new Date(date);
    newDate.setHours(0, 0, 0, 0);

    const day = newDate.getDate();
    const month = newDate.getMonth() + 1;
    const year = newDate.getFullYear();
    const resultDay = day < 10 ? `0${day}` : day;
    const resultMonth = month < 10 ? `0${month}` : month;

    return `${year}-${resultMonth}-${resultDay}`;
  }
  return null;
};

export const getLocation = (event: TEvent) => {
  const locationArray = [event.city, event.state, event.country];

  const mountedLocation = locationArray
    .filter((location) => location !== "")
    .join(" | ");

  return mountedLocation;
};

export const getMonthName = (date: Date) =>
  Intl.DateTimeFormat("pt-br", { month: "long" }).format(date);

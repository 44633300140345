import Badge from "components/ui/Badge";
import { generateEventStats } from "../../services/events";
import { EEventKind, TEvent, TLog } from "../../services/types";
import { useEffect, useMemo, useState } from "react";
import { Link, useLoaderData } from "react-router-dom";
import { formatDate, formatDateTimeShort, getLocation } from "utils/utils";

type TEventExtended = TEvent & {
  filesCount: number;
  videosCount: number;
  logs: TLog[];
};

type TLoaderResponse = {
  recentlyEvents: TEventExtended[];
  releaseEvents: TEventExtended[];
};

type TEventStats = {
  salesCount: number[];
  eventsCount: number[];
  releasesCount: number[];
};

type EventCardProps = {
  event: TEventExtended;
};

const MONTHS = [
  "Janeiro",
  "Fevereiro",
  "Março",
  "Abril",
  "Maio",
  "Junho",
  "Julho",
  "Agosto",
  "Setembro",
  "Outubro",
  "Novembro",
  "Dezembro",
];

const EventCard = ({ event }: EventCardProps) => {
  return (
    <Link
      to={`/admin/events/${event.id}`}
      key={event.id}
      className="w-full no-underline appearance-none flex flex-col p-1 gap-2 border border-gray-300 p-2 relative"
    >
      <div className="w-full flex items-center">
        <div className="h-full flex gap-5 items-center justify-between">
          <div className="w-24 h-[70px] bg-gray-200">
            {event.bannerUrl ? (
              <img
                className="h-full w-full object-cover"
                src={event.bannerUrl}
                alt={event.title}
              />
            ) : (
              <p className="h-full w-full flex items-center justify-center">
                Sem banner
              </p>
            )}
          </div>
          <div className="flex flex-col items-start">
            <h3>{event.title}</h3>
            <p>
              {getLocation(event)}{" "}
              {event.kind === EEventKind.Uploader && (
                <Badge className="bg-blue-600 mr-1">Uploader</Badge>
              )}{" "}
            </p>
          </div>
        </div>
      </div>

      <div className="">
        <div className="flex justify-between w-full gap-8">
          <p className="w-48 truncate">
            {event.compressed_video_collection_url ? (
              "Link Disponível"
            ) : (
              <span className="bg-rose-100 px-1">Link Indisponível</span>
            )}
          </p>
          <p className="w-28 truncate font-bold">
            {event.kind === EEventKind.Uploader
              ? `${event.filesCount} arquivos`
              : `${event.videosCount} vídeos`}
          </p>
        </div>
        <div>
          {event.contacts
            ?.filter((contact) => contact.notify === "true")
            .map((contact) => (
              <div
                key={contact.name}
                className="flex justify-between w-full gap-8"
              >
                <div className="w-48 truncate">
                  {contact.email ? (
                    contact.email
                  ) : (
                    <span className="bg-rose-100 px-1">Sem email</span>
                  )}
                </div>
                <div className="w-28 truncate">
                  {contact.reference ? contact.reference : "-"}
                </div>
              </div>
            ))}
        </div>
      </div>

      <div className="border-t border-gray-300 pt-2 text-xs">
        {(event.logs == null || event.logs.length === 0) && (
          <div>Sem logs para este evento</div>
        )}

        {event.logs?.map((log) => (
          <div key={log.id} className="truncate" title={log.message}>
            {formatDateTimeShort(log.createdAt as string)} - {log.message}
          </div>
        ))}
      </div>

      {event.kind === EEventKind.Uploader && (
        <div className="bg-blue-600 absolute bottom-0 left-0 w-full h-1"></div>
      )}
    </Link>
  );
};

export default function Home() {
  const { recentlyEvents, releaseEvents } = useLoaderData() as TLoaderResponse;
  const [eventsStats, setEventsStats] = useState<TEventStats | null>(null);
  const salesSum = useMemo(() => {
    return eventsStats?.salesCount.reduce((acc, count) => acc + count, 0);
  }, [eventsStats]);
  const eventsSum = useMemo(() => {
    return eventsStats?.eventsCount.reduce((acc, count) => acc + count, 0);
  }, [eventsStats]);
  const releasesSum = useMemo(() => {
    return eventsStats?.releasesCount.reduce((acc, count) => acc + count, 0);
  }, [eventsStats]);

  const recentlyEventsGroupedByDate = useMemo(() => {
    return recentlyEvents.reduce(
      (grouped: { [key: string]: TEventExtended[] }, event: TEventExtended) => {
        if (!grouped[event.date]) {
          grouped[event.date] = [];
        }

        grouped[event.date].push(event);

        return grouped;
      },
      {}
    );
  }, [recentlyEvents]);

  const groupedReleaseEvents = useMemo(() => {
    return releaseEvents.reduce(
      (
        grouped: { [key: string]: { [key: string]: TEventExtended[] } },
        event: TEventExtended
      ) => {
        const { releaseDate: releaseDateStr } = event;
        const releaseDate = new Date(releaseDateStr);
        const groupName =
          releaseDate >= new Date() ? "nextReleases" : "lastReleases";

        if (!grouped[groupName][releaseDateStr]) {
          grouped[groupName][releaseDateStr] = [];
        }

        grouped[groupName][releaseDateStr].push(event);

        return grouped;
      },
      {
        nextReleases: {},
        lastReleases: {},
      }
    );
  }, [releaseEvents]);

  const { nextReleases, lastReleases } = groupedReleaseEvents;

  useEffect(() => {
    const fetchData = async () => {
      const eventsStats = await generateEventStats(new Date().getFullYear());
      setEventsStats(eventsStats);
    };

    fetchData();
  }, []);

  return (
    <div className="mx-auto flex flex-col gap-4">
      <div className="bg-white py-10 px-8 rounded-lg">
        <div className="mb-8">
          <h2 className="font-bold">
            Dados de Eventos {new Date().getFullYear()}
          </h2>
        </div>
        <div className="space-y-4">
          <table className="w-full table-fixed">
            <thead>
              <tr>
                <th></th>
                {MONTHS.map((month) => (
                  <th key={month}>{month}</th>
                ))}
                <th>Total</th>
              </tr>
            </thead>
            <tbody>
              <tr className="text-center">
                <td>Vendas</td>
                {eventsStats?.salesCount.map((count, i) => (
                  <td key={i}>{count}</td>
                ))}
                <td>{salesSum}</td>
              </tr>

              <tr className="text-center">
                <td>Eventos</td>
                {eventsStats?.eventsCount.map((count, i) => (
                  <td key={i}>{count}</td>
                ))}
                <td>{eventsSum}</td>
              </tr>

              <tr className="text-center">
                <td>Entregas</td>
                {eventsStats?.releasesCount.map((count, i) => (
                  <td key={i}>{count}</td>
                ))}
                <td>{releasesSum}</td>
              </tr>
            </tbody>
          </table>
        </div>
      </div>

      <div className="mx-auto flex gap-4">
        <div className="w-[400px] bg-white py-10 px-8 rounded-lg">
          <div className="mb-8">
            <h2 className="font-bold">Últimos eventos (7 dias)</h2>
          </div>
          {Object.keys(recentlyEventsGroupedByDate)
            .reverse()
            .map((date) => {
              return (
                <div key={date} className="mb-8">
                  <h4 className="font-bold mb-2">{formatDate(date)}</h4>
                  <div className="space-y-4">
                    {recentlyEventsGroupedByDate[date].map((event) => (
                      <EventCard key={event.id} event={event} />
                    ))}
                  </div>
                </div>
              );
            })}
        </div>

        <div className="w-[400px] bg-white py-10 px-8 rounded-lg">
          <div className="mb-8">
            <h2 className="font-bold">Últimas Entregas (7 dias)</h2>
          </div>
          {Object.keys(lastReleases)
            .reverse()
            .map((date) => {
              return (
                <div key={date} className="mb-8">
                  <h4 className="font-bold mb-2">{formatDate(date)}</h4>
                  <div className="space-y-4">
                    {lastReleases[date].map((event) => (
                      <EventCard key={event.id} event={event} />
                    ))}
                  </div>
                </div>
              );
            })}
        </div>

        <div className="w-[400px] bg-white py-10 px-8 rounded-lg">
          <div className="mb-8">
            <h2 className="font-bold">Próximas Entregas (7 dias)</h2>
          </div>
          {Object.keys(nextReleases).map((date) => {
            return (
              <div key={date} className="mb-8">
                <h4 className="font-bold mb-2">{formatDate(date)}</h4>
                <div className="space-y-2">
                  {nextReleases[date].map((event) => (
                    <EventCard key={event.id} event={event} />
                  ))}
                </div>
              </div>
            );
          })}
        </div>
      </div>
    </div>
  );
}

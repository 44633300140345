import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import FirebaseAuthService from "../../libs/firebase/FirebaseAuthService";
import { InputText } from "components/ui/form";
import { SubmitHandler, useForm } from "react-hook-form";
import Button from "components/ui/Button";
import { GoogleIcon } from "../../assets/svg";
import { useEffect, useMemo, useState } from "react";

type Inputs = {
  email: string;
  password: string;
};

export default function Login() {
  const navigate = useNavigate();
  const location = useLocation();
  const { eventCode } = useParams();
  const params = useMemo(
    () => new URLSearchParams(location.search),
    [location]
  );
  const [message, setMessage] = useState("");
  const [error, setError] = useState("");

  const {
    register,
    formState: { errors },
    handleSubmit,
  } = useForm<Inputs>({ defaultValues: { email: "", password: "" } });

  const eventUrl = useMemo(() => {
    if (!eventCode) return "";

    return `/public/${eventCode}`;
  }, [eventCode]);

  const onSubmit: SubmitHandler<Inputs> = async (data) => {
    const { email, password } = data;

    try {
      await FirebaseAuthService.loginUser(email, password);
      navigate(eventUrl, { replace: true });
    } catch (error) {
      setError("E-mail ou senha inválidos");
      console.warn("auth error", error);
    }
  };

  const handleLoginWithGoogle = async () => {
    try {
      await FirebaseAuthService.loginWithGoogle();
      navigate(eventUrl, { replace: true });
    } catch (error) {
      console.log("Login error: ", error);
    }
  };

  useEffect(() => {
    const message = params.get("message");
    if (message) {
      setMessage(message);
    }
  }, [params]);

  useEffect(() => {
    const error = params.get("error");
    if (error) {
      setError(error);
    }
  }, [params]);

  return (
    <div className="flex h-screen items-center justify-center">
      <div className="flex min-w-[300px] flex-col items-start">
        <form
          className="w-full flex gap-3"
          autoComplete="off"
          noValidate={true}
          onSubmit={handleSubmit(onSubmit)}
        >
          <InputText
            type="email"
            label="E-mail"
            register={register("email")}
            field="email"
            isEditable={true}
            error={errors.email}
          />

          <InputText
            type="password"
            label="Senha"
            register={register("password")}
            field="password"
            isEditable={true}
            error={errors.password}
          />

          <Link
            to={`/public/${eventCode}/forgot-password`}
            className="text-blue-400"
          >
            Esqueci minha senha
          </Link>

          <Button type="submit" className="">
            Entrar
          </Button>
        </form>

        <Button
          type="button"
          onClick={handleLoginWithGoogle}
          className="mt-2 flex items-center"
        >
          <GoogleIcon width={30} height={30} />

          <span className="w-full">Continuar com Google</span>
        </Button>

        {message && <p className="text-green-600">{message}</p>}
        {error && <p className="text-red-400">{error}</p>}

        <Link
          to={`/public/${eventCode}/new-account`}
          className="text-blue-400 mt-2"
        >
          Nova conta
        </Link>
      </div>
    </div>
  );
}

type InputProps = {
  label: string;
  register: any;
  error: any;
  field: string;
  isEditable?: boolean;
  type?: string;
  defaultValue?: string;
  options?: { label: string; value: string }[];
} & React.InputHTMLAttributes<HTMLInputElement>;

function InputSelect({
  label,
  register,
  error,
  field,
  isEditable,
  options,
  ...rest
}: InputProps) {
  return (
    <div className="w-full flex flex-col items-start">
      <label htmlFor={field} className="flex items-center gap-1.5">
        {label}
        {error && <span className="text-red-500">[{error.message}]</span>}
      </label>
      <select
        id={field}
        {...register}
        aria-invalid={error ? "true" : "false"}
        {...rest}
        className={`text-gray-900 text-sm rounded-lg focus:ring-0 focus:border-none block w-full p-2.5 ${
          isEditable
            ? "bg-white border border-gray-300"
            : "bg-transparent border-transparent appearance-none"
        }`}
      >
        <option value="">Selecione</option>
        {options?.map((option) => {
          return (
            <option key={option.value} value={option.value}>
              {option.label}
            </option>
          );
        })}
      </select>
    </div>
  );
}

export default InputSelect;

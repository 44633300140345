import FirebaseStorageService from "libs/firebase/FirebaseStorageService";
import { TEvent } from "@services/types";
import Button from "components/ui/Button";
import React, { useCallback, useState } from "react";
import Cropper from "react-easy-crop";
import { updateEventBanner } from "../../services/events";

type Props = {
  event?: TEvent | null;
  isEditable?: boolean;
};

type ImgCroppedArea = {
  x: number;
  y: number;
  width: number;
  height: number;
};

export default function EventBanner({ event, isEditable }: Props) {
  const [uploadedImage, setUploadedImage] = useState<any>(null);
  const [crop, setCrop] = useState({ x: 0, y: 0 });
  const [zoom, setZoom] = useState(1);
  const [croppedArea, setCroppedArea] = useState<ImgCroppedArea>({
    x: 0,
    y: 0,
    width: 0,
    height: 0,
  });
  const [imageUrl, setImageUrl] = useState<string>(event?.bannerUrl || "");

  const [uploadProgress, setUploadProgress] = useState<number>(-1);

  const onCropComplete = (croppedArea: any, croppedAreaPixels: any) => {
    setCroppedArea(croppedAreaPixels);
  };

  const onZoomChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    setZoom(parseFloat(e.target.value));
  };

  const uploadImageHandler = (e: React.ChangeEvent<HTMLInputElement>) => {
    const file = e.target.files![0];
    if (!file) {
      return;
    }
    const fileReader = new FileReader();
    fileReader.onload = () => {
      setUploadedImage(fileReader.result);
    };
    fileReader.readAsDataURL(file);
  };

  const confirmCropHandler = useCallback(() => {
    const canvasEle = document.createElement("canvas");
    canvasEle.width = 600;
    canvasEle.height = 600;

    const context = canvasEle.getContext("2d");
    let imageObj1 = new Image();
    imageObj1.src = uploadedImage;
    imageObj1.onload = function () {
      context?.drawImage(
        imageObj1,
        croppedArea.x,
        croppedArea.y,
        croppedArea.width,
        croppedArea.height,
        0,
        0,
        canvasEle.width,
        canvasEle.height
      );

      canvasEle.toBlob(
        async (blob) => {
          if (!blob) {
            return;
          }
          const file = new File([blob], "banner.png", {
            type: "image/png",
            lastModified: Date.now(),
          });
          try {
            const downloadUrl = await FirebaseStorageService.uploadFile(
              file,
              `events/${event?.id}-${Date.now()}.png`,
              setUploadProgress
            );
            await updateEventBanner({
              eventId: event?.id as string,
              bannerUrl: downloadUrl as string,
            });

            setImageUrl(downloadUrl as string);
            setUploadedImage(null);
            setUploadProgress(-1);
          } catch (error) {
            setUploadProgress(-1);
            const errorMsg = `onCropDone.onload [${JSON.stringify(error)}]`;
            console.log(errorMsg);
          }
        },
        "image/png",
        1
      );
    };
  }, [croppedArea, event?.id, uploadedImage]);

  if (uploadedImage) {
    return (
      <div className="w-full relative h-[500px] max-w-[400px] mx-auto">
        {uploadProgress > -1 ? (
          <div className="h-full w-full flex items-center justify-center">
            <progress value={uploadProgress} max="100" />
          </div>
        ) : (
          <>
            <div className="absolute w-[400px] h-[400px]">
              <Cropper
                image={uploadedImage}
                aspect={1}
                crop={crop}
                zoom={zoom}
                maxZoom={2}
                onCropChange={setCrop}
                onZoomChange={setZoom}
                onCropComplete={onCropComplete}
              />
            </div>
            <div className="absolute bottom-0 w-full">
              <div className="flex flex-col items-start justify-center">
                <input
                  type="range"
                  className="p-0"
                  min={1}
                  max={2}
                  step={0.01}
                  value={zoom}
                  onChange={onZoomChange}
                />
                <div className="w-full flex items-center justify-around">
                  <Button
                    onClick={() => setUploadedImage(null)}
                    className="max-w-[150px] bg-rose-200"
                  >
                    Cancelar
                  </Button>
                  <Button
                    onClick={confirmCropHandler}
                    className="max-w-[150px] bg-green-200"
                  >
                    Confirmar
                  </Button>
                </div>
              </div>
            </div>
          </>
        )}
      </div>
    );
  }

  return (
    <div className="w-full relative h-[500px] max-w-[400px] mx-auto space-y-5">
      {imageUrl && <img src={imageUrl} alt={event?.title} className="w-full" />}
      {isEditable && (
        <label className="button bg-red-400 text-white">
          {imageUrl ? "Alterar Banner" : "Adicionar Banner"}
          <input
            type="file"
            accept="image/*"
            style={{ display: "none" }}
            onChange={uploadImageHandler}
          />
        </label>
      )}
    </div>
  );
}

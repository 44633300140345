import FirebaseAuthService from "../../libs/firebase/FirebaseAuthService";
import Logo from "../../assets/svg/Logo";
import React, { useCallback } from "react";
import { NavLink, useNavigate, useParams } from "react-router-dom";

function NavigationLink({
  to,
  children,
}: {
  to: string;
  children: React.ReactNode;
}) {
  return (
    <NavLink
      className={({ isActive }) =>
        [
          isActive
            ? "font-bold underline"
            : "no-underline font-semibold hover:underline",
          " rounded-t-xl p-2 text-lg whitespace-nowrap ",
        ].join(" ")
      }
      to={to}
      end
    >
      {children}
    </NavLink>
  );
}

export default function Header() {
  const { eventCode } = useParams();
  const navigate = useNavigate();

  const logoutUrl = `/public/${eventCode}/login`;

  const onLogout = useCallback(() => {
    FirebaseAuthService.logoutUser();

    navigate(logoutUrl, { replace: true });
  }, [navigate, logoutUrl]);

  return (
    <div className="w-full bg-red-400 text-white">
      <div className="h-full flex items-center justify-between max-w-6xl mx-auto">
        <NavigationLink to="/">
          <Logo width={200} />
        </NavigationLink>

        <div className="flex items-center gap-2">
          <button
            type="button"
            onClick={onLogout}
            className="button border-none hover:bg-red-300"
          >
            Logout
          </button>
        </div>
      </div>
    </div>
  );
}

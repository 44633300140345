import { TEvent, TUpload } from "@services/types";
import UploadList from "components/uploads/UploadList";
import { useLoaderData, useOutletContext } from "react-router-dom";
import { formatDate } from "utils/utils";

export default function UploadsEvent() {
  const { event } = useOutletContext() as { event: TEvent };
  const uploads = useLoaderData() as TUpload[];

  return (
    <div className="w-full flex flex-col justify-start space-y-20 p-10 bg-white">
      <section className="">
        <div className="w-full flex justify-between">
          <div className="space-y-3">
            <div className="flex items-center justify-between w-64">
              <h6>Total de arquivos:</h6>
              <p className="bg-red-100 w-28 font-bold text-center">
                {uploads.length}
              </p>
            </div>
            <div className="flex items-center justify-between w-64">
              <h6>Data de entrega:</h6>
              <p className="bg-red-100 w-28 font-bold text-center">
                {formatDate(event.releaseDate)}
              </p>
            </div>
          </div>
        </div>

        <h2 className="mb-3">Arquivos enviados</h2>

        <UploadList uploads={uploads} />
      </section>
    </div>
  );
}

export type InputProps = {
  label: string;
  register: any;
  error: any;
  field: string;
  isEditable?: boolean;
  type?: string;
  defaultValue?: string;
} & React.InputHTMLAttributes<HTMLInputElement>;

const InputText = ({
  label,
  register,
  error,
  field,
  isEditable,
  type,
  ...rest
}: InputProps) => {
  return (
    <div className="w-full flex flex-col items-start">
      <label htmlFor={field} className="flex items-center gap-1.5">
        {label}
        {error && <span className="text-red-500">[{error.message}]</span>}
      </label>
      <input
        type={type || "text"}
        id={field}
        {...register}
        aria-invalid={error ? "true" : "false"}
        {...rest}
        autoComplete="none"
        defaultValue={rest.defaultValue}
        className={
          isEditable
            ? "bg-white"
            : "bg-transparent border-transparent font-semibold text-lg"
        }
      />
    </div>
  );
};

export default InputText;

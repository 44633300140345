import React from "react";

type Props = {
  type?: "button" | "submit" | "reset";
  className?: string;
  color?: string;
  hoverColor?: string;
  disabledColor?: string;
  textColor?: string;
  successColor?: string;
  successText?: string;
  children: React.ReactNode;
} & React.ButtonHTMLAttributes<HTMLButtonElement>;

export default function Button(props: Props) {
  const {
    type = "button",
    className,
    color = "bg-red-400",
    hoverColor = "bg-red-500",
    disabledColor = "bg-red-300",
    textColor = "text-white",
    children,
    ...rest
  } = props;

  const classes = ["button", textColor];

  if (rest.disabled) {
    classes.push(disabledColor);
  } else {
    classes.push(color);
    classes.push(`hover:${hoverColor}`);
  }

  return (
    <button
      className={`${classes.join(" ")} ${className}`}
      type={type}
      {...rest}
    >
      {children}
    </button>
  );
}

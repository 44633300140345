import React from "react";
import Header from "./Header";
import { Outlet } from "react-router-dom";
import { clarity } from "react-microsoft-clarity";

export default function AppLayout() {
  if (process.env.NODE_ENV === "production") {
    clarity.init("ngjj6db2ll");
  }

  return (
    <React.StrictMode>
      <div className="theme-layout">
        <Header />
        <div className="theme-body">
          <div className="theme-container">
            <Outlet />
          </div>
        </div>
      </div>
    </React.StrictMode>
  );
}
